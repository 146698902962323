import React from 'react'

import classNames from 'classnames'

import { Container, NavigationItem, NavigationList } from './styles'
import { FiMenu, FiX } from 'react-icons/fi'
import { NavLink, useLocation } from 'react-router-dom'
import { checkMenuPermission, tabs } from '@/routes/menus'
import { useAuth } from '@/hooks/auth'
import { MenuButton } from '@/components/App/BottomTabs/styles'

const BottomTabs = () => {
  const { user } = useAuth()

  const location = useLocation()

  const [isNavigationVisible, setIsNavigationVisible] = React.useState(false)

  const menuItems = React.useMemo(() => {
    return tabs.filter(checkMenuPermission(user.permissions, user.role)) || null
  }, [user])

  const handleToggleNavigation = React.useCallback(() => {
    const navigation = document?.querySelector('.navigation')

    if (!navigation) return

    if (isNavigationVisible) {
      setIsNavigationVisible(false)
      navigation.classList.remove('navigation--visible')
    } else {
      setIsNavigationVisible(true)
      navigation.classList.add('navigation--visible')
    }
  }, [isNavigationVisible])

  React.useEffect(() => {
    const navigation = document?.querySelector('.navigation')
    if (!navigation) return
    setIsNavigationVisible(false)
    navigation.classList.remove('navigation--visible')
  }, [location.pathname])

  return (
    menuItems && (
      <Container>
        <NavigationList>
          {menuItems.map(
            ({ title, url, icon: Icon, classList = [] }, menuIdx) => (
              <NavigationItem key={`menu-item-${menuIdx}`}>
                <NavLink
                  exact
                  to={url}
                  className={classNames(['navigation__link', ...classList])}
                  activeClassName="navigation__link--active"
                >
                  <Icon size={24} />
                  {title}
                </NavLink>
              </NavigationItem>
            )
          )}

          <MenuButton>
            <a
              href="#"
              className="navigation__link"
              onClick={handleToggleNavigation}
            >
              {isNavigationVisible ? (
                <>
                  <FiX size={18} />
                  Fechar
                </>
              ) : (
                <>
                  <FiMenu size={18} />
                  Menu
                </>
              )}
            </a>
          </MenuButton>
        </NavigationList>
      </Container>
    )
  )
}

export default BottomTabs
