import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'
import navBackgroundMask from '@/assets/nav.svg'

export const Container = styled.nav`
  position: relative;
  height: 100vh;
  box-shadow: 0px 14px 34px rgb(34 37 169 / 40%);
  border-radius: 0 20px 20px 0;
  color: ${(props) => props.theme.white};
  width: 16.5rem;

  display: flex;
  flex-direction: column;

  span,
  label,
  a {
    color: ${(props) => props.theme.white} !important;
  }

  position: fixed;
  top: 0;
  left: -20rem;
  z-index: 8;

  background-color: ${(props) => props.theme.primary};

  transition: left 0.25s;

  @media (${(props) => props.theme.screens.lg}) {
    left: 0;
    margin: 1.5rem;
    border-radius: 20px;
    height: calc(100vh - 2.75rem);
    & + section {
       max-width: calc(100vw - 18.5rem);
      margin-left: 18.5rem;
    }
  }

  &.navigation::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(${navBackgroundMask});
    background-repeat: no-repeat;
    background-position: bottom;
  }

  &.navigation {
    &--visible {
      left: 0;
      width: calc(80% - 0.5rem);
      box-shadow: 0px 0px 54px rgba(0, 24, 45, 0.125);
    }
  }

  .navigation__select {
    margin-top: 1rem;

    > div {
      margin: 0;
      border-width: 0;
    }

    label {
      opacity: 1;
    }

    select {
      padding: 1rem 0.75rem;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.primary};
    }
  }

  .sign-out {
    &__container {
      width: 100%;
      box-shadow: -2px -2px 15px #0303041f;
      height: 4rem;
      margin-bottom: 0;
    }

    &__button {
      &:hover {
        background: #fff;
      }
      width: 6rem;
      background: #fff;
      height: 32px;
      margin: 0 auto;
      border-radius: 3rem;
    }
  }
`

export const NavigationHeader = styled.div`
  //height: 6em;
  padding: 2rem 1rem .5rem;
  border-bottom: 1px solid #00000008;

  height: 5.5rem;

  display: flex;
  align-items: center;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`

export const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`

export const NavigationList = styled.ul`
  padding-bottom: 240px;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 1rem;
`

export const NavigationItem = styled.li`
  position: relative;

  user-select: none;

  height: 3rem;
  font-size: 0.75rem;

  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;

  cursor: pointer;

  .navigation__link {
    height: inherit;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    text-decoration: none;

    &:hover,
    &:visited {
      color: ${(props) => props.theme.white};
    }

    &--active {
      color: ${(props) => props.theme.primary};
      position: relative;

      &:hover,
      &:visited {
        color: ${(props) => props.theme.white};
      }

      &:before {
        content: "";
        position: absolute;
        left: -1rem;
        top: 50%;
        transform: translateY(-50%);
        background: #FFF;
        width: 5px;
        height: 50%;
        border-radius: 6px;
      }
    }
  }

  svg {
    margin-right: 1rem;
  }
`

export const NavigationSubmenu = styled.ul`
  border-radius: 0.75rem;

  ${(props) =>
    props.isActive &&
    css`
      background: rgba(0, 24, 45, 0.125);
    `};

  transition: all 0.25s;

  > ${NavigationItem} {
    &:first-child {
      border-radius: inherit;
      background: rgba(0, 24, 45, 0.125);
      position: relative;
      padding: 0 1rem;
      transition: all 0.25s;

      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: "";
        display: inline-block;
        height: 6px;
        right: 1rem;
        position: absolute;
        top: calc(50% - 3px);
        transform: rotate(45deg);
        vertical-align: top;
        width: 6px;
        transition: transform 0.25s;
      }

      ${(props) =>
        props.isActive &&
        css`
          box-shadow: 0px 4px 24px rgba(0, 24, 45, 0.05);

          &:before {
            transform: rotate(135deg);
          }
        `};
    }

    &:not(:first-child) {
      display: none;

      ${(props) =>
        props.isActive &&
        css`
          display: flex;
        `};
    }
  }
`

export const NavigationInfo = styled.span`
  color: ${(props) => props.theme.text} !important;
  position: absolute;
  bottom: 2px;
  font-size: 85%;
  opacity: 0.7;
  left: 3.25rem;
`

NavigationItem.propTypes = {
  isActive: PropTypes.bool
}

NavigationSubmenu.propTypes = {
  isActive: PropTypes.bool
}
