import { useHistory, useLocation } from 'react-router-dom'

import React from 'react'

import PropTypes from 'prop-types'

const QueryContext = React.createContext({})

function useQuery () {
  const context = React.useContext(QueryContext)

  if (!context) {
    throw new Error('useContext must be used within an QueryProvider')
  }

  return context
}

const QueryProvider = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const getQuery = React.useCallback(
    () => {
      return new URLSearchParams(location.search)
    }, [location.search])

  const getQueryParams = React.useCallback(
    () => {
      return Object.fromEntries(getQuery())
    }, [getQuery])

  const removeQueryParams = React.useCallback(
    (params) => {
      const search = new URLSearchParams(location.search)

      for (const name of params) {
        search.delete(name)
      }

      history.push({ search: search.toString() })
    },
    [history, location.search]
  )

  const appendQueryParams = React.useCallback(
    (params) => {
      history.push({
        search: mergeSearchParams(params, { location }).toString()
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.search]
  )

  const query = React.useMemo(() => getQueryParams(), [getQueryParams])

  return (
    <QueryContext.Provider
      value={{
        query,
        getQuery,
        getQueryParams,
        appendQueryParams,
        removeQueryParams
      }}
    >
      {children}
    </QueryContext.Provider>
  )
}

QueryProvider.propTypes = {
  children: PropTypes.any
}

/**
 * Create a search params object and merge it (optionally) with a given query string
 */
export const mergeSearchParams = (params, { location = '' } = {}) => {
  const isEmpty = (value) =>
    value == null || value === '' || (Array.isArray(value) && !value.length)

  const search = new URLSearchParams(location.search)

  for (let [name, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value = value.filter((val) => !isEmpty(val))
    }

    if (isEmpty(value)) {
      search.delete(name)
      continue // ingnores empty, null and undefined
    }

    search.set(name, value)
  }

  return search
}

/**
 * Does the same of getQueryParams but without been reactive or trigger rerenders
 */
function getQueryParamsOnce () {
  return Object.fromEntries(
    new URLSearchParams(location?.search)
  )
}

export { useQuery, getQueryParamsOnce, QueryProvider }
