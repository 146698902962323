import React from 'react'

import PropTypes from 'prop-types'
import ToastContainer from '@/components/ToastContainer'

import { v4 as uuid } from 'uuid'
// import { getMessagingToken, onMessageListener } from '@/firebase'

export const ToastMessageProps = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired, // 'info' | 'success' | 'error'
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

const ToastContext = React.createContext({})

function useToast () {
  const context = React.useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider')
  }

  return context
}

const ToastProvider = ({ children }) => {
  const [messages, setMessages] = React.useState([])

  // React.useEffect(() => {
  //   getMessagingToken()

  //   onMessageListener((data) => {
  //     const id = uuid()

  //     const message = {
  //       id,
  //       title: data.notification.title,
  //       description: data.notification.body
  //     }

  //     setMessages((messages) => [...messages, message])
  //   })
  // }, [])

  const addToast = React.useCallback(({ type, title, description }) => {
    const id = uuid()

    const message = {
      id,
      type,
      title,
      description
    }

    setMessages((messages) => [...messages, message])
  }, [])

  const removeToast = React.useCallback((id) => {
    setMessages((messages) => messages.filter((message) => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      { children }
      <ToastContainer messages={messages}/>
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.any
}

export { useToast, ToastProvider }
