import React from 'react'

import PropTypes from 'prop-types'

import { Container } from './styles'
import { useTransition } from 'react-spring'

import Toast from './Toast'

const ToastContainer = ({ messages }) => {
  const animatedMessages = useTransition(messages, {
    keys: (message) => message.id,
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0', opacity: 1 },
    leave: { right: '-120%', opacity: 0 }
  })

  return (
    <Container className="no-print">
      {animatedMessages((style, message) => {
        return <Toast message={message} style={style} />
      })}
    </Container>
  )
}

ToastContainer.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object)
}

export default ToastContainer
