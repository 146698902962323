import { lighten, shade } from 'polished'

export default {
  default: {
    background: '#fff',
    foreground: '#F3F6FF',
    border: '1px solid #f9f9f9',

    white: '#fff',
    black: '#000',
    gray: '#606369',

    text: '#606369',
    heading: '#171a20',

    primary: '#17213C',
    info: '#37474f',
    danger: '#ed0000',
    warning: '#ff3d00',
    success: '#18c600',
    dangerLighten: '#EB5757',
    successLighten: '#27AE60',
    gradient: 'linear-gradient(90deg, #17213C, #0e1526)',

    gradientDarken: `linear-gradient(90deg, ${shade(0.05, '#17213C')}, ${shade(
      0.05,
      '#0e1526'
    )})`,

    gradientLighten: `linear-gradient(90deg, ${lighten(
      0.05,
      '#17213C'
    )}, ${lighten(0.05, '#0e1526')})`,

    pagguePurple: '#7C009F',
    paggueGreen: '#16EF96',
    paggueBlue: '#11DCF7',

    muitoGreen: '#00e277',
    muitoPink: '#FF1057',
    muitoYellow: '#FFC600',
    muitoPurple: '#BC0DFF',

    screens: {
      sm: 'min-width: 640px',
      md: 'min-width: 768px',
      lg: 'min-width: 1024px',
      xl: 'min-width: 1280px',
      xxl: 'min-width: 1536px',
      // max_xs: 'max-width: 420px',
      // max_sm: 'max-width: 639px',
      max_md: 'max-width: 767px'
      // max_lg: 'max-width: 1023px',
      // max_xl: 'max-width: 1279px',
      // max_xxl: 'max-width: 1536px'
    }
  }
}
