import { AiOutlineDollar, AiOutlineQrcode } from 'react-icons/ai'

import { format as dateFormat, startOfMonth, endOfMonth } from 'date-fns'

import {
  FiBriefcase,
  FiCreditCard,
  FiGrid,
  FiLayers,
  FiLogIn,
  FiSettings,
  FiShoppingBag,
  FiUsers,
  FiFileText,
  FiShield
} from 'react-icons/fi'

import {
  IoStorefrontOutline,
  IoWalletOutline,
  IoCarOutline,
  IoGitMergeOutline
} from 'react-icons/io5'

import { STATUS } from '@/pages/Sales/types'

const currentMonth = [
  dateFormat(startOfMonth(new Date()), 'yyyy-MM-dd'),
  dateFormat(endOfMonth(new Date()), 'yyyy-MM-dd')
]

const onlyApproveds = Object.keys(STATUS).filter(
  (item) => STATUS[item].name === 'Aprovada'
)

export const menus = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: FiGrid,
    permissions: ['dashboard:show']
  },
  {
    title: 'Empresas',
    url: '/companies',
    icon: FiBriefcase,
    permissions: ['companies:list']
  },

  {
    title: 'Estabelecimentos',
    url: '/stores',
    icon: IoStorefrontOutline,
    permissions: ['stores:list']
  },
  {
    title: 'Wallets',
    url: '/wallets',
    icon: IoWalletOutline,
    permissions: ['wallets:list']
  },
  {
    title: 'Saldos',
    url: '/companies/credits',
    icon: AiOutlineDollar,
    permissions: ['credits:list']
  },
  {
    title: 'Faturas',
    url: '/invoices',
    icon: AiOutlineQrcode,
    permissions: ['invoices:list']
  },
  {
    title: 'Extrato',
    url: '/extract',
    icon: FiFileText,
    permissions: ['extract']
  },
  {
    title: 'Grupos',
    url: '/groups',
    icon: FiLayers,
    permissions: ['groups:list']
  },
  {
    title: 'Cartões',
    url: '/cards',
    icon: FiCreditCard,
    permissions: ['cards:list']
  },
  {
    title: 'Colaboradores',
    url: '/collaborators',
    icon: FiUsers,
    permissions: ['collaborators:list']
  },
  {
    title: 'Veículos',
    url: '/vehicles',
    icon: IoCarOutline,
    permissions: ['vehicles:list']
  },
  {
    title: 'Compras',
    url: '/purchases',
    icon: FiShoppingBag,
    permissions: ['purchases:list']
  },
  {
    title: 'Vendas',
    url: `/sales?status=${onlyApproveds}`,
    icon: AiOutlineDollar,
    permissions: ['sales:list']
  },
  {
    title: 'Pagamentos',
    url: `/payments?scheduling_date=${currentMonth}`,
    icon: AiOutlineQrcode,
    permissions: ['payments:list']
  },
  {
    title: 'Configurações',
    icon: FiSettings,
    items: [
      {
        title: 'Configurações de Wallets',
        url: '/wallets-config',
        icon: IoWalletOutline,
        permissions: ['company_authorizing_parameters:list']
      },
      {
        title: 'Usuários do Portal',
        url: '/users',
        icon: FiLogIn,
        permissions: ['users:list']
      },
      {
        title: 'Perfis de Acesso',
        url: '/roles',
        icon: FiUsers,
        permissions: ['roles:list']
      },
      {
        title: 'Segmentos',
        url: '/segments',
        icon: IoGitMergeOutline,
        permissions: ['segments:list']
      },
      {
        title: 'Configurações do Sistema',
        url: '/settings',
        icon: FiShield,
        permissions: ['settings:list']
      }
    ]
  }
]

export const tabs = []

export const checkMenuPermission = (permissions, role) => {
  return (menu) => {
    if (menu.items) {
      // It woks, buts it's not correct to make side effects inside a filter funcions.
      menu.items = menu.items.filter(checkMenuPermission(permissions, role))

      if (menu.items.length === 0) {
        return false
      }
    }

    if (!menu.permissions && !menu.roles) {
      return true
    } else {
      const hasRole = !menu.roles || menu.roles?.includes(role)

      const hasPermissions =
        !menu.permissions ||
        menu.permissions.some((permission) => {
          return permissions.includes(permission)
        })

      return hasRole && hasPermissions
    }
  }
}

export const getHomePage = (user) => {
  try {
    const availableMenus = menus.filter(
      checkMenuPermission(user?.permissions, user?.role)
    ) || [{ url: null }]

    return availableMenus.find(Boolean).url || '/403'
  } catch {
    return '/403'
  }
}
