import React from 'react'

import PropTypes from 'prop-types'

import { Redirect as ReactDomRedirect, Route as ReactDomRoute } from 'react-router-dom'
import { useAuth } from '@/hooks/auth'
import { mergeSearchParams as toSearchObject } from '@/hooks/query'

const Route = ({ meta, component: Component, ...props }) => {
  const { user: isSigned, user } = useAuth()

  const mustAuthenticate = !isSigned && (meta.isPrivate || meta.permissions)

  if (mustAuthenticate) {
    const query = toSearchObject({ continue: location.href })
    const hasSignedOut = (toSearchObject({}, { location })).get('signout')
    const search = !hasSignedOut ? query.toString() : null

    return (
      <ReactDomRoute
        {...props}
        render={() => (
          <ReactDomRedirect
            to={{ pathname: '/', search }}
          />
        )}
      />
    )
  }

  if (!meta.permissions && !meta.roles) {
    return <ReactDomRoute {...props} render={() => <Component />} />
  }

  const hasPermission = meta.permissions?.some((permission) => {
    return user?.permissions.includes(permission)
  })

  const hasRole = !meta.roles || meta.roles.includes(user.role)

  if (isSigned && (!hasPermission || !hasRole)) {
    console.error(
      `The logged user has no permissions for '${meta.permissions}'`,
      user?.permissions
    )
  }

  return (
    <ReactDomRoute
      {...props}
      render={() => {
        return hasPermission && hasRole ? (
          <Component />
        ) : (
          <ReactDomRedirect to={{ pathname: '/403' }} />
        )
      }}
    />
  )
}

Route.defaultProps = {
  meta: {}
}

Route.propTypes = {
  ...ReactDomRoute.propTypes,
  meta: PropTypes.object,
  component: PropTypes.object.isRequired
}

export default Route
