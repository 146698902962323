import React from 'react'
import PropTypes from 'prop-types'
import AuthService from '@/services/AuthService'

import { api } from '@/services/api'
import MeService from '@/services/MeService'

const AuthContext = React.createContext({})

const authService = new AuthService()
const meService = new MeService()

function useAuth () {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useContext must be used within an AuthProvider')
  }

  return context
}

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = React.useState(() => {
    const { token, refresh, user, company, store } = authService.getAuthData()

    if (token && user) {
      return {
        token,
        refresh,
        user,
        company,
        store
      }
    }

    return {
    }
  })

  React.useEffect(
    function sync () {
      const syncBrowserTabsState = () => {
        console.info('AuthProvider: Auth State Synced')
        setAuthData(authService.getAuthData())
      }

      window.addEventListener('storage', syncBrowserTabsState)

      authService
        .syncUserData()
        .then((synced) => synced && syncBrowserTabsState())

      return () => {
        window.removeEventListener('storage', syncBrowserTabsState)
      }
    },
    []
  )

  const signIn = React.useCallback(async ({ login, password }) => {
    const { token, refresh, user, company, store } = await authService.signIn({
      login,
      password
    })

    setAuthData({ token, refresh, user, company, store })
  }, [])

  const signUp = React.useCallback(async (formData) => {
    await authService.signUp(formData)
    // const { token, refresh, user, company, store } =

    // setAuthData({ token, refresh, user, company, store })
  }, [])

  const authByToken = React.useCallback(async ({ authorization }) => {
    const {
      token,
      refresh,
      user,
      company,
      store
    } = await authService.authByToken({
      authorization
    })

    setAuthData({ token, refresh, user, company, store })
  }, [])

  const updateUser = React.useCallback((user) => {
    authService.setUser(user)
    setAuthData((data) => ({ ...data, user }))
  }, [])

  const impersonateUser = React.useCallback(async (user_id) => {
    const { data } = await api.get(`/auth/impersonate/${user_id}`)
    authService.updateAuthData({ data })
  }, [])

  const updateStore = React.useCallback((store) => {
    authService.setStore(store)
    setAuthData((data) => ({ ...data, store }))
  }, [])

  const signOut = React.useCallback(async () => {
    try {
      api.get('/auth/logout')
    } finally {
      setAuthData({})
      authService.signOut()
    }
  }, [])

  const getMe = React.useCallback((cached = true) => {
    return meService.execute(cached)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: authData.user,
        company: authData.company,
        signIn,
        signUp,
        signOut,
        getMe,
        authByToken,
        updateUser,
        impersonateUser,
        updateStore
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}

export { useAuth, AuthProvider }
