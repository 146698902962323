class StorageService {
  database = '';

  constructor (database) {
    this.setPrefix(database)
  }

  setPrefix (name) {
    const isInvalidName = name == null || !String(name) || !isNaN(name)

    if (isInvalidName) {
      throw new Error(
        'StorageService requires a valid string for database prefixing: NaN, empty string, undefined and null are not accepted'
      )
    }

    this.database = `@Muito:${name}:`
  }

  entity (name) {
    return this.database + name
  }

  getItem (name, fallback = null) {
    const parse = (value) => {
      try {
        return JSON.parse(value)
      } catch (e) {
        return value
      }
    }

    return parse(localStorage.getItem(this.entity(name))) || fallback
  }

  setItem (name, value) {
    const stringify = (value) => {
      return value !== undefined ? JSON.stringify(value) : ''
    }

    return localStorage.setItem(this.entity(name), stringify(value))
  }

  removeItem (name) {
    localStorage.removeItem(this.entity(name))
  }

  all () {
    return Object.entries(localStorage)
      .filter(
        ([key]) => key.indexOf(this.database) === 0
      )
      .map(
        ([key, value]) => [key.replace(this.database, ''), value]
      )
      .reduce(
        (result, [key]) => ({ ...result, [key]: this.getItem(key) }), {}
      )
  }

  clear () {
    Object.entries(localStorage)
      .map(([key]) => key)
      .filter((key) => key.indexOf(this.database) === 0)
      .forEach((key) => localStorage.removeItem(key))
  }
}

export default StorageService
