import React from 'react'

import { Centered, Content } from '@/components/Layout'
import { Info, Title } from './styles'
import { FiArrowLeft } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import notFound from '@/assets/404.gif'

const NotFound = () => {
  return (
    <Centered>
      <Content>
        <Centered>
          <img src={notFound} alt="Página não encontrada." style={{ maxHeight: 150 }}/>
        </Centered>
        <Title>
          Ops! Não encontramos esta página.
        </Title>
        <Info >
          <Link className="goback" to="/">
            <Centered>
              <FiArrowLeft style={{ marginRight: '4px' }}/>
              Voltar para o início
            </Centered>
          </Link>
        </Info>
      </Content>
    </Centered>
  )
}

export default NotFound
