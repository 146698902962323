import themes from '@/config/themes'

export const TYPES = {
  0: 'À Vista'
}

export const STATUS = {
  0: { name: 'Aprovada', color: themes.default.success },
  1: { name: 'Aprovada', color: themes.default.success },
  2: { name: 'Cancelada', color: themes.default.gray },
  3: { name: 'Cancelada', color: themes.default.gray },
  4: { name: 'Cancelada', color: themes.default.gray }
}
