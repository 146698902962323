import React from 'react'

import { AuthProvider, useAuth } from '@/hooks/auth'
import { Container, ContentContainer } from '@/components/Layout'
import { QueryProvider } from '@/hooks/query'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ToastProvider } from '@/hooks/toast'

import BottomTabs from '@/components/App/BottomTabs'
import GlobalStyle from '@/styles/global'
import Navigation from '@/components/App/Navigation'
import Routes from './routes'
import themes from '@/config/themes'
import { socket } from '@/utils/socket'

const AppLayout = () => {
  const { user: isSigned } = useAuth()

  if (!socket.client && !process.env.REACT_APP_SOCKET_DISABLED) {
    socket.start()
  }

  return (
    <ThemeProvider theme={{ ...themes.default }}>
      <GlobalStyle />
      <ToastProvider>
        <Container>
          <Router>
            <QueryProvider>
              {isSigned && <Navigation />}
              <ContentContainer>
                <Routes />
              </ContentContainer>
              {isSigned && <BottomTabs />}
            </QueryProvider>
          </Router>
        </Container>
      </ToastProvider>
    </ThemeProvider>
  )
}

const App = () => {
  return (
    <AuthProvider>
      <AppLayout />
    </AuthProvider>
  )
}

export default App
