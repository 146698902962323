import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'

const ButtonTypeVariations = {
  text: css`
    padding: 0 1rem !important;
    background: transparent;
    box-shadow: none;
    color: ${props => props.theme.heading};
    &:hover {
      background: transparent;
      color: ${props => props.theme.heading};
    }
  `,

  tab: css`
    padding: 0 1rem;
    background: transparent;
    box-shadow: none;
    color: ${props => props.theme.heading};

    ${props => props.active && css`
      border-bottom: 4px #367BF5 solid;
      border-radius: 0;
    `}

    &:hover {
      background: transparent;
      color: ${props => props.theme.heading};
      border-bottom: 4px #367BF5 solid;
      border-radius: 0;
    }
  `,

  translucid: css`
    &:hover, & {
      background: linear-gradient(45deg, #0000002e, #0000001c);
      color: ${props => props.theme.heading};
    }
  `,

  info: css`
    &:hover, & {
      background: #001d3d;
    }
  `
}

const ButtonSizeVariations = {
  large: css`
    border-radius: 8px;
    height: 3.5rem;
    padding: 0 2.5rem;
  `,

  mini: css`
    padding: 0 1.5rem;
    border-radius: 6px;
    height: 2.5rem;
    font-size: .75rem;
  `
}

export const Container = styled.button`
  height: 3rem;
  background: ${(props) => props.theme.gradient};
  border-radius: 0.5rem;
  padding: 0 2rem;
  color: ${(props) => props.theme.white};
  transition: background 0.25s;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  & + & {
    margin-left: 1rem;
  }

  &:hover {
    background: ${(props) => props.theme.gradientDarken};
  }

  &[disabled] {
    opacity: 0.35;
    cursor: not-allowed;
  }

 ${(props) => props.color && css`color: ${props.color} `};


  ${(props) =>
    props.full &&
    css`
      width: 100%;
      margin: 1rem 0;
    `};

  ${(props) => ButtonSizeVariations[props.size] || css``}

  ${(props) => ButtonTypeVariations[props.buttonType] || css``}



  ${(props) => props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
      &:hover {
        background: ${props.backgroundColor};
      }
    `};

  > a {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    text-decoration: inherit;
    color: inherit;
  }

  > svg {
    &.icon--right {
      margin-left: 0.75rem;
    }

    &.icon--left {
      margin-right: 0.75rem;
    }
  }

  .loading {
    -webkit-animation: loading 0.5s infinite linear;
    animation: loading 0.5s infinite linear;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`

Container.propTypes = {
  loading: PropTypes.bool,
  full: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string
}
