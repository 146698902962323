import styled from 'styled-components'

export const Title = styled.h2`
  width: 100%;
  color: ${(props) => props.theme.heading};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  @media print {
    font-size: 12pt;
    margin-bottom: 0.25rem;
  }
`

export const Heading = styled.h2`
  width: 100%;
  color: ${(props) => props.color || props.theme.heading};
  font-weight: 700;
  font-size: ${(props) => props.size || '1.5rem'};
  line-height: ${(props) =>
    props.size ? `calc(${props.size} + ${props.size} / 2)` : '2rem'};
  margin-bottom: 1rem;
`

export const TextBlock = styled.div`
  margin-bottom: 1.5rem;
  padding-right: 1rem;

  .title {
    display: block;
    font-size: 0.75rem;
    color: ${(props) => props.theme.text};
    line-height: 1.25rem;
  }

  .content {
    font-weight: 600;
    font-size: 1rem;
    display: block;
    color: ${(props) => props.theme.heading};
    min-height: 28px;
    display: flex;
    align-items: center;
  }

  @media print {
    margin-bottom: 1rem;
    .title {
      font-size: 8pt;
      line-height: initial;
    }

    .content {
      font-size: 10pt !important;
    }
  }
`

export const TextGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${TextBlock} {
    min-width: ${(props) => props.xs || '100%'};

    @media (${(props) => props.theme.screens.md}) {
      min-width: ${(props) => props.md || '33%'};
    }

    @media (${(props) => props.theme.screens.lg}) {
      min-width: ${(props) => props.lg || '25%'};
    }

    @media print {
      min-width: ${(props) => props.print || '25%'};
    }
  }
`
