import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
import { transparentize } from 'polished'

const ToastTypeVariations = {
  info: css`
    background: ${(props) => props.theme.info};

    ${(props) => css`
      box-shadow: 2px 1px 24px -8px ${transparentize(0.625, props.theme.info)};
    `};
  `,

  success: css`
    background: ${(props) => props.theme.success};

    ${(props) => css`
      box-shadow: 2px 1px 24px -8px ${transparentize(0.625, props.theme.success)};
    `};
  `,

  warning: css`
    background: ${(props) => props.theme.warning};

    ${(props) => css`
      box-shadow: 2px 1px 24px -8px ${transparentize(0.625, props.theme.warning)};
    `};
  `,

  error: css`
    background: ${(props) => props.theme.danger};

    ${(props) => css`
      box-shadow: 2px 1px 24px -8px ${transparentize(0.625, props.theme.danger)};
    `};
  `
}

export const Container = styled(animated.div)`
  color: ${props => props.theme.white};

  width: 22rem;
  position: relative;
  border-radius: .5rem;
  padding: 1rem 2rem 1rem 1rem;
  display: flex;
  margin-bottom: .25rem;

  ${props => ToastTypeVariations[props.type || 'info']}

  .toast {
    &__icon {
      margin-right: .75rem;
    }

    &__content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h4 {
        color: inherit;
      }

      p {
        margin-top: .25rem;
        font-size: .85rem;
      }
    }

    &__close {
      display: flex;
      align-items: center;
      position: absolute;
      right: 1rem;
      top: 1rem;
      opacity: .6;
      border: 0;
      background: transparent;
      color: inherit;

      ${props => !props.$hasDescription && css`
        transform: translateY(-50%);
        top: 50%;
      `}
    }

    ${props => !props.$hasDescription && css`
      display: flex;
      align-items: center;
    `}

  }
`
Container.propTypes = {
  type: PropTypes.string.isRequired, // 'success' | 'error' | 'info'
  $hasDescription: PropTypes.bool
}
