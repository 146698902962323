import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 0 -.5rem;

  ${props => props.$itemsCenter && css`
    align-items: center;
  `}

  ${props => props.$justifyEnd && css`
    justify-content: flex-end;
  `}

   ${props => props.$justifyBetween && css`
    justify-content: space-between;
  `}

  ${props => props.$justifyCenter && css`
    justify-content: center;
  `}
`

Row.propTypes = {
  $itemsCenter: PropTypes.bool,
  $justifyEnd: PropTypes.bool,
  $justifyCenter: PropTypes.bool,
  $justifyBetween: PropTypes.bool
}

export { Row }
