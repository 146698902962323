import React, { Suspense, lazy } from 'react'
import { Route as ReactDomRoute, Switch } from 'react-router-dom'

import LoadingBar from 'react-top-loading-bar'
import NotFound from '@/pages/NotFound'
import Route from './Route'
import themes from '@/config/themes'

const routes = [
  {
    name: 'auth',
    path: '/auth',
    component: lazy(() => import('@/pages/AuthUser'))
  },
  {
    name: 'sign-in',
    path: '/',
    exact: true,
    component: lazy(() => import('@/pages/SignIn'))
  },
  {
    name: 'sign-up',
    path: '/signup',
    component: lazy(() => import('@/pages/SignUp'))
  },
  {
    name: 'privacy-policies',
    path: '/privacy-policies',
    exact: true,
    component: lazy(() => import('@/pages/PrivacyPolicies'))
  },
  {
    name: 'mobile-app',
    path: '/download',
    exact: true,
    component: lazy(() => import('@/pages/MobileApp'))
  },
  {
    name: 'profile',
    path: '/profile',
    component: lazy(() => import('@/pages/Profile'))
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: lazy(() => import('@/pages/ForgotPassword'))
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: lazy(() => import('@/pages/ResetPassword'))
  },
  {
    name: 'dashboard',
    exact: true,
    path: '/dashboard',
    component: lazy(() => import('@/pages/Dashboard')),
    meta: {
      permissions: ['dashboard:show']
    }
  },
  {
    name: 'collaborators',
    exact: true,
    path: '/collaborators',
    component: lazy(() => import('@/pages/Collaborators')),
    meta: {
      permissions: ['collaborators:list']
    }
  },
  {
    name: 'collaborator-details',
    exact: true,
    path: '/collaborators/:id',
    component: lazy(() => import('@/pages/Collaborators/CollaboratorDetails')),
    meta: {
      permissions: ['collaborators:show']
    }
  },
  {
    name: 'vehicles',
    exact: true,
    path: '/vehicles',
    component: lazy(() => import('@/pages/Vehicles')),
    meta: {
      permissions: ['vehicles:list']
    }
  },
  {
    name: 'company-credits',
    path: '/companies/credits',
    exact: true,
    component: lazy(() => import('@/pages/Credits')),
    meta: {
      permissions: ['credits:list']
    }
  },
  {
    name: 'company-credits-allocate',
    path: '/companies/credits/allocate',
    exact: true,
    component: lazy(() => import('@/pages/Credits/AllocateCreditForm')),
    meta: {
      permissions: ['credits:create']
    }
  },
  {
    name: 'company-credits-details',
    path: '/companies/credits/:id',
    exact: true,
    component: lazy(() => import('@/pages/Credits/CreditDetails')),
    meta: {
      permissions: ['credits:show']
    }
  },
  {
    name: 'company-limit-allocate',
    path: '/limits/allocate',
    exact: true,
    component: lazy(() =>
      import('@/pages/CreditLimits/AllocateCreditLimitsForm')
    ),
    meta: {
      // permissions: ['limits:list']
    }
  },
  {
    name: 'company-invoices-list',
    path: '/invoices',
    exact: true,
    component: lazy(() => import('@/pages/CreditInvoices')),
    meta: {
      permissions: ['invoices:list']
    }
  },
  {
    name: 'company-invoices-details',
    path: '/invoices/:id',
    exact: true,
    component: lazy(() =>
      import('@/pages/CreditInvoices/CreditInvoiceDetails')
    ),
    meta: {
      permissions: ['invoices:list']
    }
  },
  {
    name: 'company-list',
    exact: true,
    path: '/companies',
    component: lazy(() => import('@/pages/Companies')),
    meta: {
      permissions: ['companies:list']
    }
  },
  {
    name: 'company-create',
    exact: true,
    path: '/companies/new',
    component: lazy(() => import('@/pages/Companies/CompanyForm')),
    meta: {
      permissions: ['companies:create']
    }
  },
  {
    name: 'company-edit',
    exact: true,
    path: '/companies/:id/edit',
    component: lazy(() => import('@/pages/Companies/CompanyForm')),
    meta: {
      permissions: ['companies:edit']
    }
  },
  {
    name: 'company-details',
    exact: true,
    path: '/companies/:id',
    component: lazy(() => import('@/pages/Companies/CompanyDetails')),
    meta: {
      permissions: ['companies:show']
    }
  },
  {
    name: 'wallets',
    exact: true,
    path: '/wallets',
    component: lazy(() => import('@/pages/Wallets')),
    meta: {
      permissions: ['wallets:list']
    }
  },
  {
    name: 'wallets-config',
    exact: true,
    path: '/wallets-config',
    component: lazy(() => import('@/pages/WalletsConfig')),
    meta: {
      permissions: ['company_authorizing_parameters:list']
    }
  },
  {
    name: 'store-list',
    exact: true,
    path: '/stores',
    component: lazy(() => import('@/pages/Stores')),
    meta: {
      permissions: ['stores:list']
    }
  },
  {
    name: 'store-create',
    exact: true,
    path: '/stores/new',
    component: lazy(() => import('@/pages/Stores/StoreForm')),
    meta: {
      permissions: ['stores:create']
    }
  },
  {
    name: 'store-details',
    exact: true,
    path: '/stores/:id',
    component: lazy(() => import('@/pages/Stores/StoreDetails')),
    meta: {
      permissions: ['stores:show']
    }
  },

  {
    name: 'store-edit',
    exact: true,
    path: '/stores/:id/edit',
    component: lazy(() => import('@/pages/Stores/StoreForm')),
    meta: {
      permissions: ['stores:edit']
    }
  },
  {
    name: 'group-list',
    path: '/groups',
    component: lazy(() => import('@/pages/Groups')),
    meta: {
      permissions: ['groups:list']
    }
  },
  {
    name: 'card-list',
    path: '/cards',
    component: lazy(() => import('@/pages/Cards')),
    meta: {
      permissions: ['cards:list']
    }
  },
  {
    name: 'users',
    path: '/users',
    component: lazy(() => import('@/pages/Users')),
    meta: {
      permissions: ['users:list']
    }
  },
  {
    name: 'roles',
    path: '/roles',
    component: lazy(() => import('@/pages/Roles')),
    meta: {
      permissions: ['roles:list']
    }
  },
  {
    name: 'purchases',
    path: '/purchases',
    component: lazy(() => import('@/pages/Purchases')),
    meta: {
      permissions: ['purchases:list']
    }
  },
  {
    name: 'sales',
    path: '/sales',
    component: lazy(() => import('@/pages/Sales')),
    meta: {
      permissions: ['sales:list']
    }
  },
  {
    name: 'payments',
    path: '/payments',
    component: lazy(() => import('@/pages/Payments')),
    meta: {
      permissions: ['payments:list']
    }
  },
  {
    name: 'extract',
    path: '/extract',
    component: lazy(() => import('@/pages/Extract')),
    meta: {
      permissions: null
    }
  },
  {
    name: 'segment-list',
    path: '/segments',
    component: lazy(() => import('@/pages/Segments')),
    meta: {
      permissions: ['segments:list']
    }
  },
  {
    name: 'settings',
    path: '/settings',
    component: lazy(() => import('@/pages/SettingsForm')),
    meta: {
      permissions: ['settings:list']
    }
  },
  // {
  //   name: 'forbidden',
  //   path: '/',
  //   exact: true,
  //   component: lazy(() => import('@/pages/Forbidden')),
  //   meta: {
  //     isPrivate: true
  //   }
  // },
  {
    name: 'forbidden',
    path: '/403',
    exact: true,
    component: lazy(() => import('@/pages/Forbidden')),
    meta: {
      isPrivate: true
    }
  }
]

const Routes = () => {
  const loadingBarRef = React.useRef(null)

  const LoadingBarEffect = () => {
    React.useEffect(() => {
      loadingBarRef.current?.continuousStart()
      return () => {
        loadingBarRef.current?.complete()
      }
    }, [])

    return <></>
  }

  return (
    <>
      <LoadingBar
        ref={loadingBarRef}
        color={themes.default.primary}
      />
      <Suspense fallback={<LoadingBarEffect />}>
        <Switch>
          {routes.map(
            ({ name, path, exact, meta, component: Component }) => {
              return (
                <Route
                  key={name}
                  path={path}
                  name={name}
                  exact={exact}
                  meta={meta}
                  component={Component}
                />
              )
            }
          )}
          <ReactDomRoute>
            <NotFound />
          </ReactDomRoute>
        </Switch>
      </Suspense>
    </>
  )
}

export default Routes
