import styled from 'styled-components'

export const ActionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  gap: 1rem;
  a {
    text-decoration: none;
    color: inherit;

    :hover, :visited {
      text-decoration: none;
      color: inherit;
    }
  }
`
