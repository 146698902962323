import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
  z-index: 10;
`
