import React from 'react'

import PropTypes from 'prop-types'

import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi'
import { Container } from './styles'
import { useToast } from '@/hooks/toast'

const icons = {
  info: <FiInfo className="toast__icon" size={24}/>,
  success: <FiCheckCircle className="toast__icon" size={24}/>,
  error: <FiAlertCircle className="toast__icon" size={24}/>
}

const Toast = ({ message, style }) => {
  const { removeToast } = useToast()

  React.useEffect(() => {
    const autoRemoveToast = setTimeout(() => {
      removeToast(message.id)
    }, 8000)

    return () => {
      clearTimeout(autoRemoveToast)
    }
  }, [removeToast, message.id])

  return (
    <Container
      type={message.type}
      $hasDescription={Boolean(message.description)}
      style={style}
      onDoubleClick={() => removeToast(message.id)}
    >
      {icons[message.type || 'info']}

      <div className="toast__content">
        <h4>{message.title}</h4>

        {message.description && <p>{message.description}</p>}
      </div>

      <button
        className="toast__close"
        type="button"
        onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>
  )
}

Toast.defaultProps = {
  message: {}
}

Toast.propTypes = {
  message: PropTypes.object,
  style: PropTypes.object
}

export default Toast
