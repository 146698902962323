import styled from 'styled-components'

import PropTypes from 'prop-types'

export const Container = styled.nav`
  width: 100%;
  height: 5.5rem;
  margin: 0 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 6;
  padding: 0 .5rem;

  @media (${(props) => props.theme.screens.lg}) {
    display: none;
  }

  @media print {
    display: none;
  }
`

export const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  justify-content: flex-end;
`

export const MenuButton = styled.li`
  border-radius: 50%;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.primary};
  color: #fff;

  position: absolute;

  height: 4rem;
  width: 4rem;
  right: 1rem;
  font-size: 0.75rem;
  margin: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  .navigation__link {
    font-size: 90%;
    padding: 0.5rem;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: inherit;

    white-space: nowrap;
  }
`

export const NavigationItem = styled.li`
  width: 20%; // 5 items

  position: relative;

  user-select: none;

  height: 4rem;
  font-size: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  .navigation__link {
    font-size: 75%;
    padding: 0 0.5rem;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: inherit;

    white-space: nowrap;

    &:hover,
    &:visited {
      color: inherit;
    }

    &--active {
      color: ${(props) => props.theme.primary};
      position: relative;

      &:hover,
      &:visited {
        color: ${(props) => props.theme.primary};
      }
    }

    &--fab {
      color: ${(props) => props.theme.white} !important;
      width: 4.75rem;
      height: 4.75rem;
      margin-top: -2.5rem;
      background: ${(props) => props.theme.gradient};
      border-radius: 50%;
    }

    svg {
      margin-bottom: 4px;
    }
  }
`

NavigationItem.propTypes = {
  isActive: PropTypes.bool
}
