import styled, { css } from 'styled-components'

import loading from '@/assets/loading.svg'

export const Container = styled.main`
  height: 100%;
  display: flex;
  align-items: stretch;
`

export const ContentContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  flex: 1;
  position: relative;
  padding: 1.5rem;
  padding-bottom: 6rem;

  @media (${(props) => props.theme.screens.lg}) {
    padding: 2rem 2.5rem;
  }

  ${(props) =>
    props.isLoading &&
    css`
      &::after {
        content: "";
        background-image: url(${loading});
        background-repeat: no-repeat;
        background-position: center;
        width: 2rem;
        height: 2rem;
        position: absolute;
        z-index: 4;
        transform: translate(-50%, -50%);
        top: 40%;
        left: 50%;

        animation: container-spin 0.5s infinite linear;

        @keyframes container-spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(359deg);
          }
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 1);
      }

      /* &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: waves;
        animation-timing-function: linear;
        background: linear-gradient(
          to right,
          rgba(243, 246, 255, 0.35) 15%,
          rgba(224, 231, 249, 0.35) 25%,
          rgb(243, 246, 255, 0.35) 33%
        );

        @keyframes waves {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 100vw 0;
          }
        }
      } */
    `}
`

export const InnerLoading = styled.div`
  position: relative;
  max-width: max-content;

  ${(props) =>
    props.fullWidth &&
    css`
      max-width: 100%;
      width: 100%;
    `}

  ${(props) =>
    props.visible &&
    css`
      &::after {
        content: "";
        background-image: url(${loading});
        background-repeat: no-repeat;
        background-position: center;
        width: 2rem;
        height: 2rem;
        position: absolute;
        z-index: 4;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

        animation: container-spin 0.5s infinite linear;

        @keyframes container-spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(359deg);
          }
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.825);
      }
    `}
`

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
