import { api } from './api'
import StorageService from './StorageService'

let instance = null

export default class AuthService {
  storage = null
  synced = false

  constructor () {
    if (!instance) {
      this.storage = new StorageService('Auth')
      instance = this
    }

    return instance
  }

  redirectToSignIn () {
    if (location.pathname !== '/') {
      location.pathname = '/'
    }
  }

  clearAuthData () {
    this.storage.clear()
  }

  getAuthData () {
    return this.storage.all()
  }

  async requestNewToken ({ refresh }) {
    try {
      const { data } = await api.post('/auth/refresh', {
        token: refresh
      })
      this.storage.setItem('token', data.refresh)
      this.storage.setItem('refresh', data.refresh)
    } catch (e) {
      this.signOut()
    }
  }

  async updateAuthData ({ data, company_id, store_id }) {
    data.company =
      data.user.companies?.find((company) => company.id === company_id) ||
      data.user.companies?.find(Boolean)

    data.store =
      data.user.stores?.find((store) => store.id === store_id) ||
      data.user.stores?.find(Boolean)

    this.storage.setItem('token', data.access_token)
    this.storage.setItem('refresh', data.refresh_token)
    this.storage.setItem('user', data.user)
    this.storage.setItem('company', data.company)
    this.storage.setItem('store', data.store)

    return data
  }

  async signIn ({ login, password }) {
    this.synced = true

    const { data } = await api.post('/auth/login', {
      login,
      password
    })

    return this.updateAuthData({ data })
  }

  async signUp (formData) {
    this.synced = true

    const isPJ = formData?.document.length === 14
    const { data } = await api.post(`/${isPJ ? 'stores' : 'collaborators'}/pre_registration`, formData)

    return isPJ ? this.updateAuthData({ data }) : null
  }

  async syncUserData () {
    if (this.synced) return

    const authorization = this.getToken()

    const { id: company_id } = this.getCompany() || {}

    const { id: store_id } = this.getStore() || {}

    return authorization
      ? this.authByToken({
        authorization,
        company_id,
        store_id
      })
      : null
  }

  async authByToken ({ authorization, company_id, store_id }) {
    this.synced = true

    const { data } = await api.get('/auth/user', {
      headers: { Authorization: `Bearer ${authorization}` }
    })

    return this.updateAuthData({ data, company_id, store_id })
  }

  signOut () {
    this.clearAuthData()
    this.redirectToSignIn()
  }

  setDeviceToken (data) {
    this.storage.setItem('device_token', data)
  }

  setToken (data) {
    this.storage.setItem('token', data)
  }

  setCompany (data) {
    this.storage.setItem('company', data)
  }

  setStore (data) {
    this.storage.setItem('store', data)
  }

  setRefresh (data) {
    this.storage.setItem('refresh', data)
  }

  setUser (data) {
    this.storage.setItem('user', data)
  }

  getToken () {
    return this.storage.getItem('token')
  }

  setMe (data) {
    this.storage.setItem('me', data)
  }

  getDeviceToken (data) {
    return this.storage.getItem('device_token', data)
  }

  getCompany () {
    return this.storage.getItem('company')
  }

  getStore () {
    return this.storage.getItem('store')
  }

  getRefresh () {
    return this.storage.getItem('refresh')
  }

  getUser () {
    return this.storage.getItem('user')
  }

  getMe () {
    return this.storage.getItem('me')
  }
}
