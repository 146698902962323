import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'

const col = 100 / 12

const Column = styled.div`
  width: auto;

  margin: 0 0.5rem;

  ${({ xs }) => xs !== 'auto' && css`
    width: calc(${col * xs}% - 1rem);
  `}

  ${({ sm }) => sm && css`
    @media (${(props) => props.theme.screens.sm}) {
      width: calc(${col * sm}% - 1rem);
    }`
  }

  ${({ md }) => md && css`
    @media (${(props) => props.theme.screens.md}) {
      width: calc(${col * md}% - 1rem);
    }`
  }

  ${({ lg }) => lg && css`
    @media (${(props) => props.theme.screens.lg}) {
      width: calc(${col * lg}% - 1rem);
    }`
  }

  ${({ xl }) => xl && css`
    @media (${(props) => props.theme.screens.xl}) {
      width: calc(${col * xl}% - 1rem);
    }`
  }

  ${({ xxl }) => xxl && css`
    @media (${(props) => props.theme.screens.xxl}) {
      width: calc(${col * xxl}% - 1rem);
    }`
  }

  ${({ print }) => print && css`
    @media print {
      width: calc(${col * print}% - 1rem);
    }`
  }
`

Column.defaultProps = {
  xs: 12
}

Column.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  print: PropTypes.number
}

export { Column }
