import Echo from 'laravel-echo'
import socketio from 'socket.io-client'
import AuthService from '@/services/AuthService'
import { Heading } from '@/components/Layout'
import themes from '@/config/themes'
import React from 'react'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const socket = { client: undefined }

function notify (title, message, redirectTo) {
  Notification.requestPermission(function () {
    const notification = new Notification(title, { body: message })
    notification.onclick = function () { window.location.replace(redirectTo) }
  })
}

socket.start = () => {
  console.info('start socket')
  const authService = new AuthService()
  const token = authService.getToken()
  const user = authService.getUser()
  const company = user?.companies.find(Boolean)
  const alert = withReactContent(Swal)

  if (token) {
    socket.client = new Echo({
      host: process.env.REACT_APP_URL_SOCKET,
      broadcaster: 'socket.io',
      key: process.env.REACT_APP_SOCKET_KEY,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      client: socketio
    })

    if (company?.id) {
      socket.client
        .private(`company.${company?.id}`)
        .listen('ChargeCreditsStatusEvent', (ev) => {
          notify('Oba!', 'Um dos seus pedidos acaba de ser aprovado!', `/companies/credits/${ev.id}`)
          alert.fire({
            title: <Heading size="1.25rem" style={{ margin: 0 }}> Um dos seus pedidos acaba de ser aprovado! </Heading>,
            showDenyButton: true,
            denyButtonText: 'Fechar',
            confirmButtonText: 'Ver detalhes',
            width: 360,
            reverseButtons: true,
            confirmButtonColor: themes.default.primary,
            denyButtonColor: themes.default.text
          }).then(async (result) => {
            if (result.isConfirmed) window.location.replace('/companies/credits/' + ev.id)
          })
        })
    }
  }
}

socket.leaveAll = () => {
  socket.client.leaveAll()
}

export { socket }
