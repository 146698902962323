import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  body {
    font-family: 'DM Sans', sans-serif;
    color: ${(props) => props.theme.text};
    font-size: 100%;
    background: ${(props) => props.theme.background};
  }

  body, html, #root {
    width: 100%;
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: .0125em;
  }


  h1, h2, h3, h4, h5, h6 {
    letter-spacing: initial;
    font-weight: 800;
    color: ${(props) => props.theme.heading};

    &.title--light {
      color: ${(props) => props.theme.text};
    }

    &.bold {
      font-family: 'Poppins', sans-serif;
    }
  }

  a {
    text-decoration: none;
  }

  input {
    color: inherit;
    border: 0;
  }

  button,input {
    font-size: .875rem;
  }

  button {
    cursor: pointer;
    font-weight: 500;
    border: 0;
  }

  .list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .no-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .noselect {
    user-select: none;
  }

  .scrollarea {
    position: relative;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 24, 45, 0.2);
      box-shadow: inset 0 0 6px rgba(0, 24, 45, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(169, 169, 169, 0.75);
      border-radius: 2px;
    }

    &--tiny {
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
      font-size: 16px; // DISABLE INPUT ZOOM FOR IOS SAFARI
    }
  }

  .to-print {
    display: none !important;
  }

  @media print {
    * {
      white-space: initial !important;
      text-overflow: initial !important;
    }

    .pagebreak--avoid { page-break-inside: avoid; }
    .pagebreak--after { page-break-after: always; }

    .no-print {
      display: none !important;
    }

    .to-print {
      display: initial !important;
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
        color: transparent;
    }

    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: transparent;
    }

    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: transparent;
    }
  }
`

export default GlobalStyles
