import styled, { css } from 'styled-components'

export const Line = styled.span`
  display: block;
  margin: 1.5rem auto;
  width: ${props => `${props.$width}px` || '100%'};

  border-bottom: 1px solid #8b939e24;

  ${props => props.$right && css`
    margin-left: auto;
    margin-right: 0;
  `}

  ${props => props.$left && css`
    margin-left: 0;
    margin-right: auto;
  `}

  @media print {
    margin: .75rem auto;
  }
`
