import styled from 'styled-components'

import { shade } from 'polished'

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
`

export const Info = styled.div`
  margin-top: 1rem;

  .goback {
      color: ${props => props.theme.primary};
      text-decoration: none;
      padding: .5rem;
      width: 100%;
      transition: color .2s;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: .5rem;
      }

      &:hover {
        color: ${props => shade(0.2, props.theme.primary)}
      }
  }
`
