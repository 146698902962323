import AuthService from './AuthService'
import axios from 'axios'

import {
  Cache,
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from 'axios-extensions'

const CACHE_DURATION = 1000 * 60 * 5 // 5 minutes

const enhacedAdapter = throttleAdapterEnhancer(
  cacheAdapterEnhancer(
    axios.defaults.adapter,
    {
      cacheFlag: 'useCache',
      enabledByDefault: false,
      defaultCache: new Cache({ maxAge: CACHE_DURATION })
    }
  )
)

const http = axios.create({
  adapter: enhacedAdapter
})

const api = axios.create({
  adapter: enhacedAdapter,
  baseURL: process.env.REACT_APP_URL_API
})

const fetchBase64 = (url) => {
  return http.get(url, { responseType: 'arraybuffer' }).then((response) => {
    const extension = response.headers['content-type'].toLowerCase()
    const content = Buffer.from(response.data, 'binary').toString('base64')
    return `data:${extension};base64,${content}`
  })
}

const setAuthenticationMiddleware = (request) => {
  const authService = new AuthService()

  const token = authService.getToken()

  request.headers.common.Authorization = `Bearer ${token}`
  request.headers.common['X-Company-ID'] = authService.getCompany()?.id
  request.headers.common['X-Store-ID'] = authService.getStore()?.id
  request.headers.common['X-Device-Token'] = authService.getDeviceToken()
  request.headers.common['X-Application'] = 'Web'

  return request
}

const refreshAuthenticationMiddleware = async (error) => {
  const authService = new AuthService()

  if (error.response?.status === 401) {
    const refreshToken = authService.getRefresh()

    if (refreshToken) {
      authService.requestNewToken(refreshToken)
    } else {
      authService.signOut()
    }
  }

  return Promise.reject(error)
}

api.interceptors.request.use(setAuthenticationMiddleware)

api.interceptors.response.use((res) => res, refreshAuthenticationMiddleware)

export { api, http, fetchBase64 }
