import AuthService from './AuthService'
import { getTime, parseISO } from 'date-fns'
import { api } from './api'

let instance = null

export default class MeService {
  constructor () {
    if (!instance) {
      this.authService = new AuthService()
      instance = this
    }

    return instance
  }

  checkCacheValid (me) {
    return (
      me?.updatedAt &&
      getTime(new Date()) - getTime(parseISO(me.updatedAt)) < me.cacheDuration
    )
  }

  async execute (cached = true) {
    if (cached) {
      const me = this.authService.getMe() || {}
      if (this.checkCacheValid(me)) return me
    }

    if (!this.executing) {
      this.executing = this.getData()
    }

    return this.executing
  }

  async getData () {
    const user = this.authService.getUser()

    const data = {
      store: {},
      company: {},
      collaborator: {},
      cacheDuration: 5000
    }

    try {
      if (user.permissions?.includes('stores:show_me')) {
        const { data: content } = await api.get('/stores/me')
        data.store = content
      }

      if (user.permissions?.includes('companies:show_me')) {
        const { data: content } = await api.get('/companies/me')
        data.company = content
      }

      if (user.permissions?.includes('collaborators:show_me')) {
        const { data: content } = await api.get('/collaborators/me')
        data.collaborator = content
      }

      data.updatedAt = new Date().toISOString()

      this.authService.setMe(data)
    } catch (error) {
      console.error(error)
    } finally {
      this.executing = null
    }

    return data
  }
}
