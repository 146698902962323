import React from 'react'

import { AiOutlineLoading } from 'react-icons/ai'
import { Container } from './styles'

const Loading = (props) => (
  <Container>
    <AiOutlineLoading
      className="loading"
      size={24}
      {...props}
    />
  </Container>
)

export default Loading
