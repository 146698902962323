import React from 'react'

import { Container } from './styles'
import Loading from '@/components/Loading'
import PropTypes from 'prop-types'

const Button = ({
  type = 'primary',
  nativeType = 'button',
  size = 'regular',
  full = false,
  children,
  loading,
  disabled,
  backgroundColor,
  color,
  ...props
}) => (
  <Container
    color={color}
    backgroundColor={backgroundColor}
    type={nativeType}
    buttonType={type}
    size={size}
    disabled={loading || disabled}
    full={full}
    {...props}
  >
    {loading ? <Loading className="loading" size={24} /> : children}
  </Container>
)

Button.propTypes = {
  loading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['large', 'regular', 'mini']),
  type: PropTypes.oneOf(['primary', 'text', 'translucid', 'info', 'tab']),
  nativeType: PropTypes.oneOf(['button', 'submit', 'reset'])
}

export default Button
