import React from 'react'

import muitoLogo from '@/assets/muito-white.svg'
import Button from '@/components/Button'

import { checkMenuPermission, menus } from '@/routes/menus'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '@/hooks/auth'
import { useQuery } from '@/hooks/query'
import {
  Container,
  NavigationHeader,
  NavigationInfo,
  NavigationItem,
  NavigationList,
  NavigationSubmenu,
  ScrollContainer
} from './styles'

const Navigation = () => {
  const { user, signOut } = useAuth()
  const { appendQueryParams } = useQuery()

  const [currentSubmenu, setCurrentSubmenu] = React.useState('')

  const handleSubmenuClick = React.useCallback(
    (name) => {
      return () => {
        currentSubmenu === name
          ? setCurrentSubmenu('')
          : setCurrentSubmenu(name)
      }
    },
    [currentSubmenu, setCurrentSubmenu]
  )

  const clearAndLogout = React.useCallback(async () => {
    appendQueryParams({ signout: 1 })
    signOut()
  }, [appendQueryParams, signOut])

  const menuItems = React.useMemo(
    () => {
      return menus.filter(checkMenuPermission(user.permissions, user.role)) || null
    }, [user]
  )

  return (
    <Container className="navigation">
      <Link className="create-account" to="/dashboard">
        <NavigationHeader>
          <img src={muitoLogo} alt="Muito" style={{ height: '30px' }} />
        </NavigationHeader>
      </Link>

      <ScrollContainer className="scrollarea scrollarea--tiny">
        <NavigationList>
          {menuItems.map(({ title, info, url, icon: Icon, items }, menuIdx) =>
            items ? (
              <NavigationSubmenu
                key={`submenu-${menuIdx}`}
                isActive={currentSubmenu === `submenu-${menuIdx}`}>
                <NavigationItem
                  onClick={handleSubmenuClick(`submenu-${menuIdx}`)}>
                  <Icon size={20} />
                  {title}
                </NavigationItem>

                {items.map(({ title, info, url, icon: Icon }, itemIdx) => (
                  <NavigationItem key={`submenu-item-${menuIdx}-${itemIdx}`}>
                    <NavLink
                      exact
                      to={url}
                      className="navigation__link"
                      activeClassName="navigation__link--active">
                      <Icon size={20} />
                      {title}
                      <NavigationInfo>{info}</NavigationInfo>
                    </NavLink>
                  </NavigationItem>
                ))}
              </NavigationSubmenu>
            ) : (
              <>
                <NavigationItem key={`menu-item-${menuIdx}`}>
                  <NavLink
                    exact
                    to={url}
                    className="navigation__link"
                    activeClassName="navigation__link--active">
                    <Icon size={20} />
                    {title}
                    <NavigationInfo>{info}</NavigationInfo>
                  </NavLink>
                </NavigationItem>
              </>
            )
          )}
        </NavigationList>
      </ScrollContainer>

      <NavigationItem className="sign-out__container">
        <Button
          onClick={clearAndLogout}
          size={'mini'}
          type="translucid"
          className="sign-out__button">
          Sair
        </Button>
      </NavigationItem>
    </Container>
  )
}

export default Navigation
